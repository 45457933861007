<template>
  <div class="px-40 border border-EEEEEE py-35 favoriteBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">我的收藏</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-30">
      <div class="flex align-center justify-between mb-25">
        <div class="flex align-center" v-if="flag">
          <div class="btn1 mr-10 cursorp" @click="allsele">全选</div>
          <div class="btn2 cursorp" @click="delehistory">删除</div>
        </div>
        <div v-else></div>
        <div v-if="!flag" class="cursorp font-14 text-222222" @click="allnosele">
          编辑
        </div>
        <div v-else class="cursorp font-14 text-222222" @click="allnosele">取消</div>
      </div>
      <div class="favoriteBox_cont" @scroll="handleScroll($event)">
        <div class="flex flex-wrap">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="mx-10 mb-40 cursorp"
            @click="gofilmdetails(item.film_id)"
          >
            <div class="imgsele">
              <div style="width: 13.125rem; height: 7.25rem">
                <el-image
                  class="rounded3"
                  style="width: 13.125rem; height: 7.25rem"
                  :src="item.img"
                  fit="cover"
                ></el-image>
              </div>
              <!-- <img :src="item.img" width="13.125rem" height="7.25rem" class="rounded3" alt="" /> -->
              <div class="imgsele_po" v-if="flag">
                <!-- <img
                  src="@/assets/img/sele_act.png"
                  width="1.625rem"
                  height="1.625rem"
                  alt=""
                  v-if="item.flag"
                  @click.stop="sele(item, index)"
                /> -->
                <el-image
                  v-if="item.flag"
                  @click.stop="sele(item, index)"
                  style="width: 1.625rem; height: 1.625rem"
                  :src="require('@/assets/img/sele_act.png')"
                  fit="cover"
                ></el-image>
                <el-image
                  v-else
                  @click.stop="sele(item, index)"
                  style="width: 1.625rem; height: 1.625rem"
                  :src="require('@/assets/img/sele.png')"
                  fit="cover"
                ></el-image>
                <!-- <img
                  src="@/assets/img/sele.png"
                  width="1.625rem"
                  height="1.625rem"
                  alt=""
                  v-else
                  @click.stop="sele(item, index)"
                /> -->
              </div>
            </div>
            <div class="font-14 mt-20 w210 text-ellipsis">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="font-14 text-666666 mt-80 text-center">
          {{ bottomtext }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      bottomtext: "",
      list: "",
      flag: false,
      page: 1,
    };
  },
  mounted() {
    this.page = 1;
    this.getdata();
  },
  methods: {
    // 跳转详情
    gofilmdetails(id) {
      this.$router.push({
        path: "/filmdetails",
        query: { type: "filmdetails", moveid: id },
      });
    },
    // 删除观赏记录
    delehistory() {
      let that = this;
      let arr = [];
      if (that.list && that.list.length) {
        that.list.forEach((item) => {
          if (item.flag) {
            arr.push(item.id);
          }
        });
      }
      let str = arr.join(",");
      console.log(str);
      this.$confirm("是否删除收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          req
            .post("index/delCollection", {
              collection_id: str,
            })
            .then((res) => {
              if (res.code != 200) {
                that.$message.error(res.msg);
                return;
              }
              that.$message.success(res.msg);
              that.page = 1;
              that.list = [];
              that.getdata();
            })
            .catch((err) => {
              console.log(err);
              that.$message.error(err.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 选择
    sele(item, i) {
      this.list[i].flag = !this.list[i].flag;
    },
    // 编辑/取消按钮
    allnosele() {
      this.flag = !this.flag;
      if (this.list && this.list.length) {
        this.list.forEach((item) => {
          item.flag = false;
        });
      }
    },
    // 全选
    allsele() {
      if (this.list && this.list.length) {
        this.list.forEach((item) => {
          item.flag = true;
        });
      }
    },
    // 获取页面滚动距离
    handleScroll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        //控制页数
        this.page = this.page + 1;
        //调用后台接口
        this.getdata();
      }
    },
    // 获取观赏记录
    getdata() {
      let that = this;
      req
        .post("center/myCollection", {
          page: that.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          res.data.data.forEach((item) => {
            item.flag = false;
          });
          that.bottomtext = "滑动加载更多~";
          if (res.data.data && res.data.data.length) {
            if (that.page == 1) {
              that.list = res.data.data;
            } else {
              that.list = [...that.list, ...res.data.data];
            }
          } else {
            that.bottomtext = "没有更多数据！";
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.favoriteBox {
  height: 100%;
  box-sizing: border-box;
  .imgsele {
    position: relative;
    &_po {
      position: absolute;
      top: 5%;
      left: 1%;
      cursor: pointer;
    }
  }
  &_cont {
    overflow: auto;
    height: 21.875rem;
  }
  &_cont::-webkit-scrollbar {
    display: none;
  }
  .w210 {
    width: 13.125rem;
  }
  .btn1 {
    width: 4.9375rem;
    height: 1.875rem;
    background: #ffffff;
    border: 1px solid #ef6247;
    color: #e7524c;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.875rem;
  }
  .btn2 {
    width: 4.9375rem;
    height: 1.875rem;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    color: #666666;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.875rem;
  }
}
</style>
